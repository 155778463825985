$(document).on("turbolinks:load", function(){
  $(document).ready(function(){
    var validation = $('#new_order').val();
    if (validation == ""){
      $('#new_order').validate({
        rules: {
          // user validations
          "user[first_name]": {
            required: true,
            nameregex: true,
            minlength: 2,
            maxlength: 40
          },
          "user[last_name]": {
            required: true,
            nameregex: true,
            minlength: 2,
            maxlength: 40
          },
          "user[email]": {
            required: true,
            email: true,
            emailregex: true
          },
          "user[password]": {
            required: true,
            minlength: 6,
            maxlength: 15,
            passwordregex: true
          },
          // order validations
          "order[subscriber_name]": {
            required: true,
            usernameregex: true,
            minlength: 3,
            maxlength: 40
          },
          "order[email]": {
            required: true,
            email: true,
            emailregex: true
          },
          "order[password]": {
            required: true,
            minlength: 6,
            maxlength: 15,
            passwordregex: true
          },
          "order[phone]": {
            phoneregex: true,
            number: true,
            minlength: 10,
            maxlength: 10,
            required: true
          },
          "order[first_name]": {
            required: true,
            nameregex: true,
            minlength: 2,
            maxlength: 40
          },
          "order[last_name]": {
            required: true,
            nameregex: true,
            minlength: 2,
            maxlength: 40
          },
          "order[address]": {
            minlength: 2,
            maxlength: 100,
            required: true,
            addressregex: true
          },
          "order[zip]": {
            required: true,
            number: true,
            maxlength: 5,
            zipregex: true
          },
          "order[city]": {
            cityregex: true,
            required: true,
            minlength: 2,
            maxlength: 40
          },
          "order[state]": {
            required: true,
            cityregex: true,
            minlength: 2,
            maxlength: 40
          },
          // military validations
          "military_detail[work]": {
            required: true,
            nameregex: true,
            minlength: 2,
            maxlength: 40
          }, 
          "military_detail[first_name]": {
            required: true,
            nameregex: true,
            minlength: 2,
            maxlength: 40
          },
          "military_detail[last_name]": {
            required: true,
            nameregex: true,
            minlength: 2,
            maxlength: 40
          },
          "military_detail[state]": {
            required: true,
            cityregex: true,
            minlength: 2,
            maxlength: 40
          },
          "military_detail[zip_code]": {
            required: true,
            number: true,
            maxlength: 5,
            zipregex: true
          },
        },
        messages: {
          // User validation messages
          "user[first_name]": {
            nameregex: 'The first name can only consist of alphabets.',
            required: 'The first name is required.',
            minlength: 'The first name must be 2-40 characters long.',
            maxlength: 'The first name must be 2-40 characters long.'
          },
          "user[last_name]": {
            nameRegex: 'The last name can only consist of alphabets.',
            required: 'The last name is required.',
            minlength: 'The last name must be 2-40 characters long.',
            maxlength: 'The last name must be 2-40 characters long.'
          },
          "user[email]": {
            required: 'The email is required.',
            email: 'The email pattern must followed.',
            emailregex: 'Contains @ and .'
          },
          "user[password]": {
            required: 'The password is required.',
            minlength: 'The password must be 6-15 characters long.',
            maxlength: 'The password must be 6-15 characters long.',
            passwordregex: 'The password must be 6-15 characters long, should contain letters and numbers only.'
          },
          // order validation messages
          "order[subscriber_name]": {
            minlength: 'The subscriber name must be 3-40 characters long.',
            maxlength: 'The subscriber name must be 3-40 characters long.',
            required: 'The subscriber name is required.',
            usernameregex: 'Contains alphabets and number only.'
          },
          "order[email]": {
            required: 'The email is required.',
            email: 'The email pattern must followed.',
            emailregex: 'Contains @ and .'
          },
          "order[password]": {
            required: 'The password is required.',
            minlength: 'The password must be 6-15 characters.',
            maxlength: 'The password must be 6-15 characters.',
            passwordregex: 'The password must contain letters and numbers only.'
          },
          "order[phone]": {
            phoneregex: 'The phone contain only 10 digits.',
            number: 'Only numbers are allowed.',
            maxlength: 'Maximum 10 digits are allowed.',
            required: 'The phone number is required.'
          },
          "order[first_name]": {
            nameregex: 'The first name can only consist of alphabets.',
            required: 'The first name is required.',
            minlength: 'The first name must be 2-40 characters long.',
            maxlength: 'The first name must be 2-40 characters long.'
          },
          "order[last_name]": {
            nameRegex: 'The last name can only consist of alphabets.',
            required: 'The last name is required.',
            minlength: 'The last name must be 2-40 characters long.',
            maxlength: 'The last name must be 2-40 characters long.'
          },
          "order[address]": {
            minlength: 'The address must be 2-100 characters long.',
            maxlength: 'The address must be 2-100 characters long.',
            required: 'The address is required.',
            addressregex: 'Contains alphabets, numbers and some special characters.'
          },
          "order[zip]": {
            maxlength: 'Maximum 5 digits are allowed.',
            number: 'Only numbers are allowed.',
            zipregex: 'It contains 5 digits number.',
            required: 'The zip is required.'
          },
          "order[city]": {
            cityregex: 'City Contains alphabets, space and . only.',
            required: 'The city is required.',
            minlength: 'The city must be 2-40 characters long.',
            maxlength: 'The city must be 2-40 characters long.'
          },
          "order[state]": {
            cityregex: 'State Contains alphabets, space and . only.',
            required: 'The state is required.',
            minlength: 'The state must be 2-40 characters long.',
            maxlength: 'The state must be 2-40 characters long.'
          }
        }
      });
    }
    else { 
      $("#order_subscriber_name").rules("remove", "minlength maxlength required usernameregex");
      $("#order_email").rules("remove", "minlength maxlength required ");
    }

    $.validator.addMethod('usernameregex', function(value){
      return /^[a-zA-Z0-9 _]+$/.test(value);
    },'Contains alphabets, number and _ only.');

    $.validator.addMethod('emailregex', function(value, element){
      // return this.optional(element) || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      // return this.optional(element) || /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value);
      return this.optional(element) || /^\b[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(value);
    },'Contains alphabets and number only.');

    $.validator.addMethod('passwordregex', function(value, element) {
      // return this.optional(element) || /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,15}$/i.test(value);
      return this.optional(element) || /^[a-zA-Z0-9]{6,15}$/.test(value);
    }, 'Must follow the password pattern');

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    },'Contains alphabets only');

    $.validator.addMethod('phoneregex', function(value, element){
      return this.optional(element) || /\d{10}\b/g.test(value);
    },'Contains digits only');

    $.validator.addMethod('zipregex', function(value, element){
      return this.optional(element) || /\d{5}\b/g.test(value);
    },'Contains digits only');

    $.validator.addMethod('cityregex', function(value){
      return  /^[a-zA-Z .]+$/.test(value);
    },'Contains alphabets, space and . only.');

    $.validator.addMethod('addressregex', function(value){
      return  /^[a-zA-Z0-9 !@$%*,(){}]+$/.test(value);
    },'Contains alphabets, space and . only.');
  });
});

